import {React} from "react";
import { Table } from "react-bootstrap";
import './pricing.scss';
import NavBar from "../nav-bar/Nav";

export default function Pricing(){
    const site = process.env.REACT_APP_SITE || 'https://app.signetrisk.com';
    const plans = [
        {
            id: 'enterpriseBasic',
            name: 'SignetRisk for Enterprise',
            price: '$44.95/mo',
            discoveryReports: true,
            cloudSecReports: true,
            invite: false,
            riskscenarios: true,
            multiProviders: true,
            api: true,
            aggregationReports: false,
            route: `${site}/signup?product=Enterprise`
        },
        {
            id: 'planTwo',
            name: 'SignetRisk for Brokers',
            price: '$9.95/mo per company (10 minimum)',
            discoveryReports: true,
            cloudSecReports: true,
            invite: true,
            riskscenarios: false,
            multiProviders: true,
            api: true,
            aggregationReports: false,
            route: `${site}/signup?product=Portfolio`
        },
        {
            id: 'planThree',
            name: 'SignetRisk for Underwriters',
            price: '$19.95/mo per company (25 minimum)',
            discoveryReports: true,
            cloudSecReports: true,
            invite: true,
            riskscenarios: false,
            multiProviders: true,
            api: true,
            aggregationReports: true,
            route: `${site}/signup?product=Portfolio Premium`
        },
        {
             id: 'planFour',
             name: 'SignetRisk for Vendor Managers',
             price: '9.95/mo per company (10 minimum)',
             discoveryReports: true,
             cloudSecReports: true,
             invite: true,
             riskscenarios: false,
             multiProviders: true,
             api: true,
             aggregationReports: true,
             route: `${site}/signup?product=Portfolio`
        }
    ]
    const handlePlanCheckout = (route) => {
      window.location.href = route
    }

    return(
      <div>
        <NavBar />
        <div className="container padded-container">
          <div className="pricing-header px-3 py-3 pb-md-4 mx-auto text-center">
            <h1 className="display-4">Pricing</h1>
            <p className="lead">Get started with 2 days of access to SignetRisk for Enterprise for free. All subscriptions are billed monthly in advance and you can cancel at any time. Our plans are designed to be flexible to allow our clients to choose the right level of access for their needs.</p>
          </div>
          <Table className="comparison-table">
            <thead className="comparison-table-header">
                <tr>
                    <th></th>
                    {plans.map(plan => (
                        <th className={plan.id === 'planThree' ? "comparison-table-plans featured-plan plan-header" : "comparison-table-plans"}>
                            <h5>{plan.name}</h5><p>{plan.price}</p>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody style={{fontSize: '16px'}}>
              <tr>
                <th>Level 1 Cyber Risk Assessment Report</th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.discoveryReports ? 'done' : 'remove'}</span>
                  </td>
                ))}
              </tr>
              <tr>
                <th>Level 2 Cyber Risk Assessment Reports</th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.cloudSecReports ? 'done' : 'remove'}</span>
                  </td>
                ))}
              </tr>
              <tr>
                <th>Custom Invitation Code/Url </th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.invite ? 'done' : 'remove'}</span> </td>
                ))}
              </tr>
              <tr>
                <th>Risk Scenario Models</th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.riskscenarios ? 'done' : 'remove'}</span>
                  </td>
                ))}
              </tr>
              <tr>
                <th>Level 2 assessments - Multiple cloud providers</th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.multiProviders ? 'done' : 'remove'}</span>
                  </td>
                ))}
              </tr>
              <tr>
                <th>API Support</th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.api ? 'done' : 'remove'}</span>
                  </td>
                ))}
              </tr>
              <tr>
                <th>Risk Aggregation Data and Reports<br></br><i></i></th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.aggregationReports ? 'done' : 'remove'}</span>
                  </td>
                ))}
              </tr>
              <tr>
                <th></th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? "featured-plan-cell last table-bg--gray" : ''}>
                    <button onClick={(e) => {
                      e.preventDefault();
                      handlePlanCheckout(plan.route)}} 
                      className="btn btn-primary btn-block w-100">Select</button>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    )
}
