import React from "react";
import { Carousel } from "react-bootstrap";
import NavBar from "../nav-bar/Nav";
import './solutions-page.scss';

export default function Solutions() {
    const services = [
        {
            id: 'serviceOne',
            icon: 'assured_workload',
            title: 'Risk Managers and Enterprise Cyber Risk Quantification',
            description: 'Using SignetRisk allows an organization and its risk management team to quickly get a straightforward view of the organizations exposure to a variety of cyber risk scenarios.  Scenario models can be easily customized in a way that that the loss estimates represent the organizations own view of risk and its risk appetite, Scenario loss estimates are guided by both Level 1 and Level 2 cyber risk assessment data which represent risk odds. Using scenario loss estimates facilitates an informed discussion externally between the organzation and its insurance broker and internally with its own board and governance teams.'
        },
        {
            id: 'serviceTwo',
            icon: 'query_stats',
            title: 'Insurance Brokers, Underwriters, and Re-insurers',
            description: 'SignetRisk provides a range of capabilities that support the entire cyber insurance industry from brokers to re-insurers.  Brokers can leverage the the Enterprise Cyber Risk Management capabilities in a managed platform to interact with their clients and to help prepare them understand their risk exposures.  Underwriters can leverage the SignetScore based Level 1 cyber risk assessment for fast underwriting, and Level 2 clould risk assessments for deeper insights into the clients security posture.  Re-insurers can leverage the underlying technology and control data for modeling risk aggregation and diversification.'
        },
        {
            id: 'serviceThree',
            icon: 'fact_check',
            title: 'Vendors and Third Party Risk Management',
            description: 'SignetRisk enables a fact based cyber risk assessment process by allowing vendor managers to upload a portfolio of vendors and quickly start monitoring their Level 1 cyber risk assessements.  Additionally, for critical Tier 1 vendors they can trigger a Level 2 cyber risk assessment request which can provide them with deeper understanding of their vendors cyber security posture.  By using SignetRisk, vendors only need to onboard their data into the SignetRisk platform once by configuring the appropriate AWS/Azure connectors.  Once in place the data is automatically kept up to date and can be shared with vendor managers upon request.'
        }
    ]

    return(
        <div>
        <NavBar />

          <div className="marketing bg-color-5">
              <div className="container">
                  <div className="row featurette">
                  <div className="col-md-8">
                      <h2 className="featurette-heading color-1">SignetRisk for Enterprise. <span className="color-4">Cyber risk quantification that starts with the enterprise. </span></h2>
                      <p className="lead" style={{color: 'white'}}> Enterprise cyber data and risk views are the core that drive both cyber insurance and vendor risk management conversations. They represent facts that seed the bottom up risk quantification conversation instead of a top-down approach.
                      </p>
                      <p className="lead" style={{color: 'grey'}}> 
* Enterprise cloud risk signals and data * Customizable cyber risk quantification scenario models * Vendor risk signals * Informed board/C-suite risk management decisions and discussions *    
                      </p>

                  </div>
                  <div className="col-md-4" style={{textAlign: 'center'}}>
                      <span className="material-icons icon-xl color-1">assured_workload</span>
                  </div>
                  </div>
              </div>
          </div>
          <div className="marketing">
              <div className="container">
                  <div className="row featurette">
                      <div className="col-md-8 order-md-2">
                          <h2 className="featurette-heading color-7"> SignetRisk for cyber brokers. <span className="text-muted"> Enable active client participation</span></h2>
                          <p className="lead"> 
                          Actively colloborate with clients as they prepare for cyber insurance coverage by providing them them the tools they need to understand their cyber risk exposures. 
                          </p>
                          <p className="lead" style={{color: 'grey'}}> 
* Broker portfolio manager * Customized invitation code/url for clients. * Fast Level 1 cyber risk assessment scores * Request on-platform Level 2 cyber assessement sharing from clients *    
                          </p>
                      </div>
                      <div className="col-md-4 order-md-1" style={{textAlign: 'center'}}>
                          <span className="material-icons icon-xl color-6">troubleshoot</span>
                      </div>
                  </div>
              </div>
          </div>
          <div className="marketing" style={{background: '#dedede'}}>
              <div className="container">
                  <div className="row featurette">
                      <div className="col-md-8">
                          <h2 className="featurette-heading color-7"> SignetRisk for Cyber Underwriters and Re-Insurers. <span className="text-muted"> Level 1 and Level 2 cyber risk assessments via a single platform.</span></h2>
                          <p className="lead"> 
                          Start with fast Level 1 cyber risk assessments and follow up with more detailed Level 2 assessements as needed. Enable longitudinal analysis of risk signals and controls with consistent structured risk analytics. 
                         </p>
                          <p className="lead" style={{color: 'grey'}}> 
* Level 1 & 2 cyber risk assessments * SignetScore based single trigger underwriting * Cloud security posture data and reports * Risk aggregation analysis and data *    
                          </p>
                      </div> 
                      <div className="col-md-4 order-md-1" style={{textAlign: 'center'}}>
                          <span className="material-icons icon-xl color-2">multiline_chart</span>
                      </div>
                  </div>
              </div>
          </div>


          <div className="marketing bg-color-5">
              <div className="container">
                  <div className="row featurette">
                  <div className="col-md-8">
                      <h2 className="featurette-heading color-1">SignetRisk for Vendor Cyber Risk Management <span className="color-4"> Cyber facts and reality not opinions or compliance reports </span></h2>
                      <p className="lead" style={{color: 'white'}}> Work collaboratively with vendors to obtain Level 1 and Level 2 cyber risk assessemnts without creating burdensome workflows.  Incentivize vendors to provide information they already have rather than filling out ardous forms and spreadsheets.
                      </p>
                      <p className="lead" style={{color: 'grey'}}>
* Provide vendors with customzed invitation code/url *  Request on-platform Level 2 cyber assessments * Vendor portfolio management dashboard *
                      </p>

                  </div>
                  <div className="col-md-4" style={{textAlign: 'center'}}>
                      <span className="material-icons icon-xl color-1">fact_check</span>
                  </div>
                  </div>
              </div>
          </div>



          <div className="container" style={{paddingTop: '40px'}}>
              <div className="row">
                  {services.map(service => (
                      <div key={service.id} className="col-lg-4">
                          <div className="tile">
                              <span className="material-icons">{service.icon}</span>
                              <h2 className="tile-title">{service.title}</h2>
                              <p className="tile-description">{service.description}</p>
                          </div>
                      </div>
                  ))}
              </div>
          </div>



        </div>
    )
}
