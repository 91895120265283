import React from "react";
import { Carousel } from "react-bootstrap";
import NavBar from "../nav-bar/Nav";
import './landing-page.scss';

export default function Landing() {
    const slides = [
        {
            id: 'slideOne',
            heading: 'Enterprise Cyber Risk Quantification',
            caption: 'Providing organizations with a holistic view of their cyber risk exposure to a range of risk scenarios such as business interruption, data loss, recovery costs and legal fines.'
        },
        {
            id: 'slideTwo',
            heading: 'Fast and Accurate Level 1 Cyber Risk Assessments',
            caption: 'Predictive SignetScore and associated data provide advanced Level 1 Cyber Risk Assessments that do not suffer from IP address/asset mapping inaccuracies.'
        },
        {
            id: 'slideThree',
            heading: 'Detailed Level 2 Cyber Risk Assessments',
            caption: 'Streamlined workflows to enable the request and onboarding of level 2 cyber risk assessments based on AWS/Azure from vendors or cyber insurance applicants.'
        },
        {
            id: 'slideFour',
            heading: 'Technology Aggregation Risk Data and Analysis',
            caption: 'Digital signature based detection of technology dependencies for reliable and trustworthy risk aggregation and diversification analysis.'
        },
        {
            id: 'slideFive',
            heading: 'Portfolio Cyber Risk Management',
            caption: 'Leverage Level 1 and Level 2 Cyber Risk  Assessments together for all your Tier 1 vendors as a part of your TPRM program.'
        }
    ]

    const services = [
        {
            id: 'serviceOne',
            icon: 'query_stats',
            title: 'Level 1 & 2 Cyber Risk Assessments',
            description: 'Fast and scalable Level 1 cyber risk assessments allow organizations to rapidly deploy cyber risk assessment capability in a wide range of applications. SignetScore is the most predictive and accurate cyber risk score available that does not rely on inherently inaccurate IP address based scanning data. Detailed Level 2 cyber risk assessments provide a deeper view into an organization cloud cyber security posture. '
        },
        {
            id: 'serviceTwo',
            icon: 'assured_workload',
            title: 'Enterprise Cyber Risk Loss Scenarios',
            description: 'Cyber risk quantification starts with the enterprise which has the best view into its own data and its own risk appetite. Customizable and straightforward allow an organization to rapidly develop a high level view of their cyber risk expousures which enable decisions regarding risk transfer via insurance, risk retention and most importantly transparent communication of these important operational parameters with the board and other goveranance forums. ' 
        },
        {
            id: 'serviceThree',
            icon: 'bookmarks',
            title: 'Portfolio Risk Aggregation',
            description: 'A longitudinal view of cyber risk exposure is enabled by aggregating detailed Level 1 & 2 cyber risk assessments across a large number of organizations.  Such analysis can enable an understanding of deep and often hidden dependencies that emerge when collections of organizations are grouped together. Portfolio risk aggregation data enables a clearer understading of diversification and the resilience of a portfolio to specific system risks.'
        }
    ]

    return(
        <div>
          <div className="gradient">
            <NavBar />
              <Carousel style={{position: 'relative', zIndex: 1, marginTop: '-100px'}}>
                  {slides.map(slide => (
                      <Carousel.Item key={slide.id}>
                          <Carousel.Caption className="carousel-slide text-start px-5 align-items-start">
                              <h1>{slide.heading}</h1>
                              <p>{slide.caption}</p>
                          </Carousel.Caption>
                      </Carousel.Item>
                  ))}
              </Carousel>
          </div>
          
          <div className="container" style={{paddingTop: '40px'}}>
              <div className="row">
                  {services.map(service => (
                      <div key={service.id} className="col-lg-4">
                          <div className="tile">
                              <span className="material-icons">{service.icon}</span>
                              <h2 className="tile-title">{service.title}</h2>
                              <p className="tile-description">{service.description}</p>
                          </div>
                      </div>
                  ))}
              </div>
          </div>



          
          <div className="marketing bg-color-5">
              <div className="container">
                  <div className="row featurette">
                  <div className="col-md-8">
                      <h2 className="featurette-heading color-1">Level 1 and Level 2 Cyber Risk Assessments in a single platform. <span className="color-4">Balance speed and depth of the assessment as needed. </span></h2>
                      <p className="lead" style={{color: 'white'}}> SignetScore provides the most accurate Level 1 Cyber Risk Assessment summary available, while allowing the depth of the assessment to be customized by adding Level 2 assessments based on AWS/Azure cloud security posture data.</p>
                      
                  </div>
                  <div className="col-md-4" style={{textAlign: 'center'}}>
                      <span className="material-icons icon-xl color-1">troubleshoot</span>
                  </div>
                  </div>
              </div>
          </div>

          <div className="marketing">
              <div className="container">
                  <div className="row featurette">
                      <div className="col-md-8 order-md-2">
                          <h2 className="featurette-heading color-7"> Enterprise Cyber Loss Scenarios. <span className="text-muted"> Cyber risk quantification that starts with the enterprise</span></h2>
                          <p className="lead"> Enterprise cyber data and risk views are the core that drive both cyber insurance and vendor risk management conversations. They represent facts that seed the bottom up risk quantification conversation instead of a top-down approach.</p>
                      </div>
                      <div className="col-md-4 order-md-1" style={{textAlign: 'center'}}>
                          <span className="material-icons icon-xl color-6">assured_workload</span>
                      </div>
                  </div>
              </div>
          </div>

          <div className="marketing" style={{background: '#dedede'}}>
              <div className="container">
                  <div className="row featurette">
                      <div className="col-md-8">
                          <h2 className="featurette-heading color-7"> Cyber Risk Aggregation Data. <span className="text-muted"> Portfolio diversification by technology category.</span></h2>
			  <p className="lead"> Accurate digital fingerprint based technology identification data based on direct measurements and observations. Carefully organized, annotated and ready to use in our platform or yours. </p>
                      </div>
                      <div className="col-md-4 order-md-1" style={{textAlign: 'center'}}>
                          <span className="material-icons icon-xl color-2">schema</span>
                      </div>
                  </div>
              </div>
          </div>



        </div>
    )
}
